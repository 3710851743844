import { Injectable } from '@angular/core';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import debug from 'debug';

import { UserAdmin } from '../../entities/user-admin.entity';
import { HttpService } from '../../services/http.service';
import { SessionState } from '../../store/session';
import { AuthService } from '../auth/auth.service';

interface GetUser {
  force?: boolean;
}

@Injectable()
export class UserStore {
  private readonly log = debug(UserStore.name);

  private readonly userToken = '_TEMP_USER_';

  private readonly delayToken = '_TEMP_DELAY_';

  @Emitter(SessionState.setUser)
  private readonly setUser: Emittable<UserAdmin>;

  @SelectSnapshot(SessionState.getUser)
  private readonly user: UserAdmin;

  constructor(private _httpService: HttpService) {}

  public get snapshot(): UserAdmin {
    return this.user;
  }

  async get(options: GetUser = {}): Promise<UserAdmin> {
    if (!options.force) {
      const stored = this.stored();

      if (stored) {
        this.log('obtendo do cache');
        return stored;
      }
    }

    const user = await this.request();

    this.store(user);

    return user;
  }

  store(user?: UserAdmin): void {
    if (user) {
      this.setUser.emit(user);
    } else {
      this.clearStorage();
    }
  }

  private request(): Promise<UserAdmin | null> {
    console.log('Requesting user');
    return this._httpService.as(UserAdmin).get('auth/session');
  }

  private stored(): UserAdmin | null {
    return this.user;
  }

  private clearStorage(): void {
    this.setUser.emit(null);
  }
}

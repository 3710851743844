<span
  class="relative inline-flex"
  [ngClass]="SIZE_MAP[size]"
>
  <ng-container *ngIf="isLoading else notLoading">
    <span
      [class]="'object-cover w-full h-full bg-gray-300 animate-pulse ' + loadingBackground"
      [ngClass]="{ 'rounded-full': variant === Variant.CIRCLE, 'rounded': variant === Variant.ROUNDED }"

    >
    </span>
  </ng-container>
  <ng-template #notLoading>
    <img
      *ngIf="src"
      alt="Avatar"
      class="object-cover w-full h-full"
      [src]="src"
      [ngClass]="[{ 'rounded-full': variant === Variant.CIRCLE, 'rounded': variant === Variant.ROUNDED }, customClass]"
    >
    <span
      *ngIf="!src && userName"
      class="flex items-center justify-center w-full h-full rounded-full bg-primary-500"
      [ngClass]="customClass"
    >
      <span
        class="font-medium uppercase select-none text-on-primary-500"
        [ngClass]="FONT_SIZE_MAP[size]"
      >
        {{ getUserInitials(userName) }}
      </span>
    </span>
  </ng-template>
</span>

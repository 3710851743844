import { NgModule } from '@angular/core';
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../../environments/environment';

import { ApplicationState } from './application';
import { SessionState } from './session';

@NgModule({
  imports: [
    NgxsStoragePluginModule.forRoot({
      key: [SessionState],
    }),
    NgxsModule.forRoot([SessionState, ApplicationState], {
      developmentMode: !environment.production,
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsEmitPluginModule.forRoot(),
  ],
})
export class StoreModule {}

import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { DefaultEntity } from 'app/services/default.entity';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { UserAdmin } from '../../entities/user-admin.entity';
import { HttpService } from '../../services/http.service';

import { Auth } from './auth.entity';
import { AuthError } from './errors';
import { InvalidCredentialsError } from './errors/invalid-credentials.error';

interface SignIn {
  login: string;
  senha: string;
}

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  public readonly login$ = new EventEmitter<UserAdmin>();

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _httpService: HttpService,
    private _userService: UserService
  ) {}

  isAuthenticated(): boolean {
    return this._authenticated || !!this._userService.getSnapshot();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get expiresIn(): number {
    return Number(localStorage.getItem('expiresIn')) ?? 0;
  }

  /**
   * Setter & getter for expires in
   */
  set expiresIn(expiresIn: number) {
    localStorage.setItem('expiresIn', expiresIn?.toString());
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(cpf: string): Promise<any> {
    return this._httpService
      .as(DefaultEntity)
      .post('user/admin/recovery/email', {
        cpf,
      });
  }

  /**
   * Reset password
   *
   * @param password
   */
  async resetPassword(password: string): Promise<any> {
    const user = await this._userService.get();

    return this._httpService
      .post(`user/admin/${user.id}/password`, {
        password,
      })
      .toPromise();
  }

  setAuthenticatedUser(auth: Auth): void {
    // Store the access token in the local storage
    this.accessToken = auth.token;

    // Store the access token in the local storage
    this.expiresIn = auth.expiresIn;

    this._authenticated = true;

    // Store the user on the user service
    this._userService.user = auth.user;

    this.login$.emit(auth.user);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  async signIn(credentials: SignIn): Promise<Auth> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      throw new AuthError('O usuário já está autenticado.');
    }

    try {
      const response = await this._httpService
        .as(Auth)
        .post('auth/session', credentials);

      this.setAuthenticatedUser(response);

      return response;
    } catch (error) {
      throw new InvalidCredentialsError(error);
    }
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post('api/auth/refresh-access-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          // Store the access token in the local storage
          this.expiresIn = response.expiresIn;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    this._userService.user = null;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  async check(): Promise<boolean> {
    try {
      // Check if the user is logged in
      if (this._authenticated) {
        return true;
      }

      // Check the access token availability
      if (!this.accessToken) {
        return false;
      }

      // Check the access token expire date
      if (!(await this._userService.get())) {
        return false;
      }

      // If the access token exists and it didn't expire, sign in using it
      return true;
    } catch (e) {
      console.warn('auths', e);
      return false;
    }
  }
}

import { Component, Input } from '@angular/core';

import {
  AvatarFontSizeMap,
  AvatarSizeMap,
  Size,
  Variant,
} from './i-avatar.types';

@Component({
  selector: 'i-avatar',
  templateUrl: './i-avatar.component.html',
})
export class ImagineAvatarComponent {
  @Input()
  public src: string;

  @Input()
  public variant: Variant = Variant.CIRCLE;

  @Input()
  public userName: string;

  @Input()
  public size: Size = Size.SMALL;

  @Input()
  customClass: string = '';

  /**
   * Se true, é exibido efeito de carregamento até que o estado seja alterado para false novamente.
   */
  @Input()
  public isLoading = false;

  @Input()
  public loadingBackground: string;

  // ? Utilizado no HTML
  public Variant = Variant;
  public SIZE_MAP = AvatarSizeMap;
  public FONT_SIZE_MAP = AvatarFontSizeMap;

  constructor() {}

  getUserInitials(userName: string): string {
    if (!userName) {
      return '?';
    }

    const names = userName.split(' ');

    if (names.length === 1) {
      return names[0].charAt(0);
    }

    return names[0].charAt(0) + names[names.length - 1].charAt(0);
  }
}
